.root {
  padding: 85px 10px 140px;
}
.card {
  position: relative;
  margin-bottom: 25px;
  padding: 20px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 1px 4px rgba(0, 0, 0, .16);

  &-jikobango {
    padding: 5px 10px;
    display: inline-flex;
    align-items:  center;
    background-color: #eee;
    font-size: 24px;
    color: #000;

    label {
      margin-right: 10px;
      font-weight: bold;
      font-size: 18px;
      color: #666;
      
      &::after {
        margin-left: 10px;
        content: '|';
        color: #fff;
      }
    }
  }

  &-title {
    margin: 10px 0;
    font-weight: bold;
    font-size: 56px;
    color: #000;
  }

  &-info {
    padding: 4px;
    display: flex;
    border: 2px solid #eee;
    border-radius: 4px;

    > * {
      width: 50%;
    }
  }

  .folderName {
    margin-top: 35px;
    display: flex;
    min-height: 24px;
    font-size: 18px;
    line-height: 1.2;
    color: #666;

    &::before {
      flex-shrink: 0;
      content: '';
      margin-top: 4px;
      margin-right: 4px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #bf0000;
      border-radius: 50%;
    }
  }
  .folderGroup {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }


  &-text-label-item {
    display: inline-flex;
    label {
      background-color: rgba(191,0,0,.16);
      color: #bf0000;
      padding: 2px 10px;
      font-size: 30px;
      align-items: center;
      display: flex;
      font-weight: bold;
    }
    span {
      margin-left: 10px;
      font-size: 50px;
    }
  }

  &-datas {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    &-item {
      position: relative;
      margin-bottom: 20px;
      width: calc(50% - 10px);
      button {
        border: none;
        background-color: #ffffff;
        width: 100%;
        padding: 0px;
        cursor: pointer;
      }
    }
  }

  &-button-field-save {
    padding: 12px 20px 20px;
    position: fixed;
    left: 0;
    bottom: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #ccc;
    border-top: 2px solid #666;

    button {
      padding: 4px 10px !important;
      max-width: 540px;
      height: 48px;
      font-size: 28px !important;
    }
  }
}
.tiff {
  margin-top: 20px;
  width: 100%;
}

.favButton {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 48px;
  cursor: pointer;
}
.root {
  .inputField {
    display: flex;
    justify-content: flex-end;
    &-title {
      justify-content: flex-end;
      display: flex;
      align-items: center;
      font-size: 26px;
    }
    input {
      font-size: 26px;
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 5px;
    }
    p {
      font-size: 20px;
    }
    input::-webkit-input-placeholder { 
      padding-top: 0.2em;
    }
  }
}

._required {
  color: #bf0000;
}
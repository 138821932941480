.TextLabel {
  padding: 5px;
  display: inline-flex;
  background-color: #eee;
  font-weight: bold;
  font-size: 14px;
  line-height: 1.2;
  color: #666;
  white-space: nowrap;
}

.TextLabel--large {
  padding: 5px 20px;
  font-size: 20px;
}
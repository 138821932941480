.root {
  padding: 85px 10px 140px;

  &.offline {
    padding-top: 139px;
  }
}
.list {

  .card {
    position: relative;
    margin-bottom: 25px;
    padding: 20px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, .16);

    &-jikobango {
      padding: 5px 10px;
      display: inline-flex;
      align-items:  center;
      background-color: #eee;
      font-size: 24px;
      color: #000;

      label {
        margin-right: 10px;
        font-weight: bold;
        font-size: 18px;
        color: #666;

        &::after {
          margin-left: 10px;
          content: '|';
          color: #fff;
        }
      }
    }
  }

  &--error {
    .card {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 400px;
    }
  }

  .itemCardList {
  }


  .folderName {
    margin-top: 35px;
    display: flex;
    min-height: 24px;
    font-size: 18px;
    line-height: 1.2;
    color: #666;

    &::before {
      flex-shrink: 0;
      content: '';
      margin-top: 4px;
      margin-right: 4px;
      display: inline-block;
      width: 12px;
      height: 12px;
      background-color: #bf0000;
      border-radius: 50%;
    }
  }


  .cardInfo {
    margin-top: 10px;
    display: flex;
    align-items: flex-end;
    border-bottom: 4px solid #bf0000;

    & > * {
      margin-bottom: 10px;
      margin-left: 10px;
    }
  }
  .cardInfoTitle {
    margin-right: 20px;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 40px;
    line-height: 1.2;
    color: #000;
  }

  .folderGroup {
    margin-top: 12px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }


  .deleteButton {
    position: absolute;
    top: 12px;
    right: 12px;
    padding: 2px 12px;
    width: auto;
    height: auto;
    font-size: 14px;
  }

  &-button {
    margin-top: 60px;
  }
}

.card-button-field-save {
  padding: 12px 20px 20px;
  position: fixed;
  left: 0;
  bottom: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #ccc;
  border-top: 2px solid #666;

  button {
    padding: 4px 10px !important;
    max-width: 540px;
    height: 48px;
    font-size: 28px !important;
  }
}

.Error {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 130px);

  &__base {
    padding: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 730px;
    width: 100%;
    min-height: 400px;
    background-color: #fff;
    border-radius: 8px;
    color: #666 !important;
    text-align: center;
  }

  &__title {
    margin-bottom: 50px;
    font-size: 32px;
    line-height: 1;
    text-align: center;
  }
}

.root {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%; 
  
  .header {
    font-size: 35px;
    margin-bottom: 20px;
    font-family: inherit;
    font-weight: bold;

    &-button {

      button {
        width: auto;
      }
    }
  }
}

.currentFavorite {

  &::before {
    display: block;
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MjcuODcgNTExLjkxIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojZmZmO30uYntmaWxsOiM2NjY7fTwvc3R5bGU+PC9kZWZzPjxwYXRoIGNsYXNzPSJhIiBkPSJNMzIzLjA1LDE3OC4yMSwyNjQuNDYsNTcuNjIsMjA2LDE3OC4yMUEyNy4xOSwyNy4xOSwwLDAsMSwxODUuODEsMTkzTDU0LDIxMi40MWw5NS43MSw5NC44YTI3LDI3LDAsMCwxLDcuNDcsMjMuNTFMMTM0LjY4LDQ2NGwxMTcuMTktNjIuNjFhMjYuNTUsMjYuNTUsMCwwLDEsMjUuMTcsMEwzOTQuMjQsNDY0LDM3MS43MywzMzAuNzJhMjcuMDUsMjcuMDUsMCwwLDEsNy41OC0yMy41MUw0NzUsMjEyLjQxLDM0My4yMiwxOTNBMjYuODEsMjYuODEsMCwwLDEsMzIzLjA1LDE3OC4yMVoiLz48cGF0aCBjbGFzcz0iYiIgZD0iTTUyNi43LDE5My43YTI0LDI0LDAsMCwwLTE5LjMtMTYuMkwzNTQuMSwxNTQuOCwyODUuNSwxMy41MmEyNCwyNCwwLDAsMC00My4yLDBMMTczLjcsMTU0LjgsMjAuNDksMTc3LjVBMjMuOTMsMjMuOTMsMCwwLDAsNy4xMSwyMTguMkwxMTguMiwzMjguNCw5MS4xLDQ4My45Yy0uNiw5LDMuMSwxOC4yLDEwLjUsMjMuNXMxNy4zLDYsMjQuNSwxLjdsMTM3LjgtNzMuMiwxMzcsNzMuMmM3LjIsNC4zLDE3LjksMy42LDI1LjMtMS43YTI0LDI0LDAsMCwwLDkuNy0yMy41TDQwOS42LDMyOC40LDUyMC44LDIxOC4yQTI0LjA1LDI0LjA1LDAsMCwwLDUyNi43LDE5My43Wk0zODMuOTQsMzE4Ljg0bDI1LDE1OC0xNDAtNjgtMTQ5LDc0LDIxLTE2NC0xMDYtMTE1LDE1OC0zNiw3MC45My0xMzAsNjcuMDcsMTM1LDE2OSwzMVoiLz48cGF0aCBjbGFzcz0iYiIgZD0iTTI2My44NywzNy44NGwtNzAuOTMsMTMwLTE1OCwzNiwxMDYsMTE1LTIxLDE2NCwxNDktNzQsMTQwLDY4LTI1LTE1OCwxMTYtMTE1LTE2OS0zMVpNNDc1LDIxMi40MWwtOTUuNjksOTQuOGEyNy4wNSwyNy4wNSwwLDAsMC03LjU4LDIzLjUxTDM5NC4yNCw0NjQsMjc3LDQwMS4zNGEyNi41NSwyNi41NSwwLDAsMC0yNS4xNywwTDEzNC42OCw0NjRsMjIuNS0xMzMuMjNhMjcsMjcsMCwwLDAtNy40Ny0yMy41MUw1NCwyMTIuNDEsMTg1LjgxLDE5M0EyNy4xOSwyNy4xOSwwLDAsMCwyMDYsMTc4LjIxTDI2NC40Niw1Ny42Mmw1OC41OSwxMjAuNTlBMjYuODEsMjYuODEsMCwwLDAsMzQzLjIyLDE5M1oiLz48L3N2Zz4=');
    width: 40px;
    height: 40px;
  }
}
.currentFavorite_on {
  &::before {
    display: block;
    content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1NzYgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMS4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBzdHJva2U9IiNmZmYiIGZpbGw9IiNCRjAwMDAiIGQ9Ik0zODEuMiAxNTAuM0w1MjQuOSAxNzEuNUM1MzYuOCAxNzMuMiA1NDYuOCAxODEuNiA1NTAuNiAxOTMuMUM1NTQuNCAyMDQuNyA1NTEuMyAyMTcuMyA1NDIuNyAyMjUuOUw0MzguNSAzMjguMUw0NjMuMSA0NzQuN0M0NjUuMSA0ODYuNyA0NjAuMiA0OTguOSA0NTAuMiA1MDZDNDQwLjMgNTEzLjEgNDI3LjIgNTE0IDQxNi41IDUwOC4zTDI4OC4xIDQzOS44TDE1OS44IDUwOC4zQzE0OSA1MTQgMTM1LjkgNTEzLjEgMTI2IDUwNkMxMTYuMSA0OTguOSAxMTEuMSA0ODYuNyAxMTMuMiA0NzQuN0wxMzcuOCAzMjguMUwzMy41OCAyMjUuOUMyNC45NyAyMTcuMyAyMS45MSAyMDQuNyAyNS42OSAxOTMuMUMyOS40NiAxODEuNiAzOS40MyAxNzMuMiA1MS40MiAxNzEuNUwxOTUgMTUwLjNMMjU5LjQgMTcuOTdDMjY0LjcgNi45NTQgMjc1LjktLjAzOTEgMjg4LjEtLjAzOTFDMzAwLjQtLjAzOTEgMzExLjYgNi45NTQgMzE2LjkgMTcuOTdMMzgxLjIgMTUwLjN6Ii8+PC9zdmc+');
    width: 40px;
    height: 40px;
  }
}
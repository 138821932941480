* {
    box-sizing: border-box;
    font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3", "Helvetica", "Arial", sans-serif;
    word-break: break-all;
}

body {
    background-color: #eee;
    font-family: "メイリオ", "Meiryo", "Hiragino Kaku Gothic ProN","ヒラギノ角ゴ ProN W3", "Helvetica", "Arial", sans-serif;
    color: #666;
}
.TextLabelItem {
  margin-right: 1em;
  display: inline-flex;
  align-items: center;

  & > * {
    display: flex;
    align-items: center;
  }

  & > span {
    margin-left: 5px;
    font-size: 24px;
    line-height: 1.2;
    color: #000;
  }
}
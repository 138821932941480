.contents, .overlay {
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
  visibility: hidden;
  pointer-events: none;
  z-index: -900;
}
.contents {
  background-color: rgba(0, 0, 0, .85);
  display: block;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.overlay {
  background-color: rgba(0, 0, 0, 0.7);
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
}
.is-active {
  .contents, .overlay {
    opacity: 1;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: visible;
    pointer-events: auto;
  }
  .contents {
    z-index: 999;
  }
  .overlay {
    z-index: 800;
  }
}
.close-btn {
  background-color: #333;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  height: 28px;
  position: absolute;
  right: -10px;
  top:  -10px;
  width: 28px;
  &::before, &::after {
    background: #fff;
    content: "";
    display: block;
    height: 2px;
    left: 25%;
    position: relative;
    transition: all .5s ease-in-out;
    width: 50%;
  }
  &::before {
    top: 45%;
    transform: rotate(45deg);
  }
  &::after {
    top: calc(45% - 2px);
    transform: rotate(-45deg);
  }
}
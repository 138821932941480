.root {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    font-size: 28px;
    font-family: inherit;
    font-weight: bold;

    &-title {
      cursor: pointer;
    }

    &-subtitle {
      display: inline-flex;
      align-items: center;
      font-size: 20px;
      font-weight: normal;
      white-space: nowrap;

      span {
        margin: 0 16px;
      }
    }

    &-bar {
      justify-content: space-between;
    }

    &-btns {
      display: flex;
      align-items: flex-end;
    }

    &-button {
      margin-left: 10px;
    }
    &-button-logout {
      display: flex;
      align-items: center;

      span {
        margin: 0 16px;
        font-size: 20px;
        font-weight: normal;
      }

      button {
        background-color: #ddd !important;
        color: #666 !important;
      }
    }

    &-offline {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 54px;
      background-color: #666c77;
      border-bottom: 2px solid #000;
      font-size: 20px;
      color: #fff;
      text-align: center;

      &::before {
        margin-right: 10px;
        content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA1MTIgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMS4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNTA2LjMgNDE3bC0yMTMuMy0zNjRjLTE2LjMzLTI4LTU3LjU0LTI4LTczLjk4IDBsLTIxMy4yIDM2NEMtMTAuNTkgNDQ0LjkgOS44NDkgNDgwIDQyLjc0IDQ4MGg0MjYuNkM1MDIuMSA0ODAgNTIyLjYgNDQ1IDUwNi4zIDQxN3pNMjMyIDE2OGMwLTEzLjI1IDEwLjc1LTI0IDI0LTI0UzI4MCAxNTQuOCAyODAgMTY4djEyOGMwIDEzLjI1LTEwLjc1IDI0LTIzLjEgMjRTMjMyIDMwOS4zIDIzMiAyOTZWMTY4ek0yNTYgNDE2Yy0xNy4zNiAwLTMxLjQ0LTE0LjA4LTMxLjQ0LTMxLjQ0YzAtMTcuMzYgMTQuMDctMzEuNDQgMzEuNDQtMzEuNDRzMzEuNDQgMTQuMDggMzEuNDQgMzEuNDRDMjg3LjQgNDAxLjkgMjczLjQgNDE2IDI1NiA0MTZ6Ii8+PC9zdmc+');
        display: block;
        width: 20px;
        height: 20px;
        transform: translateY(2px);
      }
    }
  }

}

.ImageWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: calc(100vh - 130px);

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
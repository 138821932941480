.root {
  padding: 85px 10px 65px;
}
.results {

  &-cell-kakutei { width: 72px; flex-shrink: 0; }
  &-cell-syouken { width: 16.5%; flex-shrink: 0; }
  &-cell-keiyaku { width: 100%; }
  &-cell-shozaichi { width: 16.5%; flex-shrink: 0; }
  &-cell-jiko { width: 21.4%; flex-shrink: 0; }
  &-cell-ctrl { width: 104px; flex-shrink: 0; }

  &-header {
    padding: 0 10px;
    display: flex;
    justify-content: center;
    width: calc(100% + 20px);
    height: 40px;
    background-color: #666;
    border-top: 4px solid #333;
    border-bottom: 4px solid #333;
    font-weight: bold;
    font-size: 16px;
    color: #fff;
    transform: translate(-10px, -20px);

    div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    div:nth-child(3),
    div:nth-child(5) {
      background-color: #737373;
    }
  }

  &-list {
    margin-bottom: 20px;

    &-item {
      display: flex;
      justify-content: center;
      width: 100%;
      background-color: #fff;
      border: 2px solid #666;
      border-radius: 4px;

      & + & {
        margin-top: 25px;
      }

      & > div {
        padding: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 22px;
      }
      & > .results-cell-kakutei {
        font-size: 24px;
      }
      & > div:nth-child(2),
      & > div:nth-child(4),
      & > div:nth-child(6) {
        background-color: rgba(238, 238, 238, .4);
      }
      .results-cell-kakutei {
        color: #e67801;
      }
      .results-cell-kakutei-true {
        color: #bf0000;
      }

      button {
        padding: 12px !important;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none !important;
        background-color: #e67801 !important;
        font-size: 22px;
        line-height: 1;
        color: #fff !important;

        &::after {
          margin-left: 4px;
          content: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNTYgNTEyIj48IS0tISBGb250IEF3ZXNvbWUgUHJvIDYuMS4xIGJ5IEBmb250YXdlc29tZSAtIGh0dHBzOi8vZm9udGF3ZXNvbWUuY29tIExpY2Vuc2UgLSBodHRwczovL2ZvbnRhd2Vzb21lLmNvbS9saWNlbnNlIChDb21tZXJjaWFsIExpY2Vuc2UpIENvcHlyaWdodCAyMDIyIEZvbnRpY29ucywgSW5jLiAtLT48cGF0aCBzdHJva2U9IiNmZmYiIGZpbGw9IiNmZmYiIGQ9Ik02NCA0NDhjLTguMTg4IDAtMTYuMzgtMy4xMjUtMjIuNjItOS4zNzVjLTEyLjUtMTIuNS0xMi41LTMyLjc1IDAtNDUuMjVMMTc4LjggMjU2TDQxLjM4IDExOC42Yy0xMi41LTEyLjUtMTIuNS0zMi43NSAwLTQ1LjI1czMyLjc1LTEyLjUgNDUuMjUgMGwxNjAgMTYwYzEyLjUgMTIuNSAxMi41IDMyLjc1IDAgNDUuMjVsLTE2MCAxNjBDODAuMzggNDQ0LjkgNzIuMTkgNDQ4IDY0IDQ0OHoiLz48L3N2Zz4=');
          display: inline-block;
          width: 10px;
          height: 20px;
          transform: translate(2px, -1px);
        }

        &.results-kakutei-true {
          background-color: #bf0000 !important;
        }
      }
    }
  }
}

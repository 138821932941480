.root {
  position: sticky;
  bottom: 0;
  width: 100%;

  &-button {

    button {
      width: auto;
    }
  }
}

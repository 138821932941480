.Search {
  padding-top: 85px;
  height: 100%;

  &__inner {
    width: 100%;
    height: 100%;
  }
  &__logo {
    margin: 0px 0px 10px;
    text-align: center;

    img {
      max-width: 380px;
      width: 66%;
      height: auto;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__stage {
    padding: 40px 40px 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: calc(100% - 160px);
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    margin-bottom: 35px;
  }

  &__history_stage {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 1024px;
    width: calc(100% - 160px);
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
  }

  &__required {
    margin-bottom: 32px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: rgba(191, 0, 0, .08);
  }
  &__not-required {
    padding: 0 32px;
  }

  &__border-dashed {
    padding: 20px;
    background-image : linear-gradient(to right, #333, #333 2px, transparent 7px, transparent 8px);
    background-size: 12px 1.5px;
    background-position: left bottom;
    background-repeat: repeat-x; 
    width: 100%;
  }

  &__history-required {
    padding: 0 32px;
  }

  &__history-text {
    margin: 0 0 10px;
    font-size: 28px;
    color: #333;
  }

  &__required-text {
    margin: 0 0 20px;
    font-size: 28px;
    color: #333;
  }

  &__wrap {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
  }

  &__input {

    & + & {
      margin-top: 25px;
    }

    & > div > div > div:first-child {
      width: 200px;
    }

    input {
      width: 400px;

      @media screen and (max-width: 956px) {
        width: 100%;
      }
    }
  }

  &__submit {
    margin: 30px 0 0px;
    width: 400px;
  }

  &__history {
    margin: 0px 0 0px;
    width: 400px;
    
    .search-history {
      background-color: #e67801 !important;
    }
  }
}

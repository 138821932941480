.Error {
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 130px);

  .Error__base {
    padding: 40px;
    max-width: 730px;
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    color: #666 !important;
    text-align: center;
  }

  .Error__large-icon {
    font-size: 60px;
  }

  .Error__title {
    margin-bottom: 50px;
    font-size: 32px;
    line-height: 1;
    text-align: center;
  }
  .Error__main-text {
    padding: 0 20px;
    font-size: 24px;
  }
  .Error__sub-text {
    font-size: 24px;
    color: #000 !important;
  }

  .Error__sub-base {
    margin-top: 15px;
    padding: 30px;
    display: inline-block;
    width: 100%;
    background-color: rgba(191, 0, 0, .08);
    box-shadow: none;
    font-size: 24px;

    ul {
      margin: 0;
    }
  }

  .Error__link-button {
    margin: 12px auto;
    background-color: #fff;
    width: 260px;
    font-size: 1.35rem;
    color: #900;
  }

  ._lft {
    text-align: left !important;
  }
}

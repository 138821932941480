.root {
  position: fixed;
  bottom: 0;
  width: 100%;

  .footer {
    font-size: 28px;
    font-family: inherit;
    font-weight: bold;

    button {
      width: auto !important;
    }
  }
}
.disp-none {
  display: none;
}

.Login {
  padding-top: 85px;
  height: 100%;
}

.Login__inner {
  width: 100%;
  height: 100%;
}
.Login__logo {
  margin: 90px 0;
  text-align: center;

  img {
    max-width: 760px;
    width: 66%;
    height: auto;
  }
}
.Login__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.Login__stage {
  padding: 100px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1024px;
  width: calc(100% - 160px);
  background-color: #fff;
  border-radius: 8px;
  text-align: center;
}

.Login__title {
  margin-top: 0;
  margin-bottom: 48px;
  font-size: 24px;
  font-weight: bold;
}

.Login__input {
  text-align: left;

  & + & {
    margin-top: 40px;
  }
}

.Login__submit {
  margin: 60px 0 24px;
  width: 400px;
}

.Login__link {
  cursor: pointer;
}
.Login__info {
  margin: 40px 0 0;
  font-size: 14px;
  text-align: left;
}

.version {
  position: fixed;
  right: 20px;
  bottom: 20px;
  font-size: 13px;
}
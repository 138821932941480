.button {
  border: 5px none;
  border-radius: 5px;
  cursor: pointer;
  padding: 2px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: 700;
  height: 100%;
  width: 100%;

  &:disabled {
    cursor: default;
    background-color: #444 !important;
    color: #000;
  }

  &.primary {
    background-color: #BF0000;
    color: #ffffff;
  }
  &.secondary {
    background-color: #ffffff;
    border: 1px solid;
    color: #000
  }
  &.tertiary {
    background-color: #fff;
    border: 1px solid #3827B1;
    color: #3827B1;
  }
  &.quaternary {
    background-color: #eee;
    border: 1px solid #666;
    color: #666;
  }

  &.ss {
    font-size: 14px;
    height: 32px;
    padding: 6px 10px;
  }

  &.sm {
    font-size: 25px;
    padding: 10px;
  }

  &.md {
    font-size: 20px;
    height: 45px;
    padding: 6px 20px;
    width: 180px;
  }

  &.lg {
    font-size: 40px;
    padding: 10px;
  }
  > * {
    height: 100%;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }
}
